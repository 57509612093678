<template>
  <div>
    <div class="card p-2">
      <b-tabs vertical nav-wrapper-class="nav-vertical">
        <b-tab title="Profile">
          <div class="row">
            <div class="col-6">
              <label for="">First Name</label>
              <input type="text" class="form-control" v-model="firstname" />
            </div>
            <div class="col-6">
              <label for="">Last Name</label>
              <input type="text" class="form-control" v-model="lastname" />
            </div>
            <div class="col-6 mt-1">
              <label for="">Email </label>
              <input
                type="text"
                class="form-control"
                v-model="email"
                :disabled="true"
              />
            </div>
            <div class="col-12">
              <button class="btn btn-primary mt-1" @click="updateProfile()">
                Update Profile
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="2FA"> 
          <TwoFactorAuth/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTabs, BTab } from "bootstrap-vue";
import TwoFactorAuth from "@/views/components/TwoFactorAuth.vue";
export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
    };
  },
  components: {
    BTabs,
    BTab,
    TwoFactorAuth
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    updateProfile() {
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/update?user_id=${this.$store.state.auth.user_id}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserDetails() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/show?user_id=${this.$store.state.auth.user_id}`,
      };
      this.$http(options)
        .then((res) => {
          this.firstname = res.data.data.firstname;
          this.lastname = res.data.data.lastname;
          this.email = res.data.data.email;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
